<script lang="ts">
  import Appconfig from "@root/app.config";
  let times = 0;
  const handleOpenDebug = () => {
    times = times + 1;
    if (times >= 9) {
      (window as any).localStorage.setItem("debug", "on");
    } else {
      if (window.localStorage.getItem("debug") === "on") {
        (window as any).localStorage.setItem("debug", "off");
      }
    }
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="mx-2" on:click={handleOpenDebug}>{Appconfig.title}</div>
